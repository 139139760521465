<template>
    <v-select :items="list_data" ref="myInput" :value="content" @input="handleInput()" :label="label" :id="name" :name="name" :rules="formRules"
       
       @change="setChange" outlined rounded dense>
    </v-select>
</template>
<script>
    export default {
        data() {
            return {
                 formRules: [],
                 content:this.value
            };
        },
        methods: {
            setFormRules() {
                let validate = this.validation; 
                let tmp_validate = [];
                 if (validate) {
                    validate.forEach(rule => {
                        switch (rule) {
                            case 'required':
                                tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                            break;
                        }
                    });
                 }                
               

                 this.formRules = tmp_validate;
            },
             setValue(id){
                 console.log(id);
                 this.content = id
            },
            
            handleInput (e) {
                this.$emit('input', this.$refs.myInput.value)
            },
            setChange() {
                this.$emit('setChange', this.$refs.myInput.value);
            },
        },
        mounted() {
           this.setFormRules();
        },
        created(){
           this.content = this.value

        },
        props: ['label', 'name', 'value','validation','list_data'],
        name: 'formInput',
    }
</script>